:root {
  /* --main-color: #111; */
  --loader-color:red;
  --back-color: #fff;
  --time: 3s;
  --size: 5px;
}

.loader {
	background-color: var(--main-color);
	overflow: hidden;
  width: 100%;
  /* height: 100%; */
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: center;
  align-content: center; 
  justify-content: flex-start;  
	z-index: 100000;
}

.loader__element {
	height: var(--size);
	width: 100%;
	background: var(--back-color);

}

.loader__element:before {
	content: '';
	display: block;
	background-color: var(--loader-color);
	height: var(--size);
	width: 0;
  animation: getWidth var(--time) ease-in infinite;
}

@keyframes getWidth {
	100% { width: 100%; }
}






/* .overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99999;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
