.footer-container {
  background-color: lightgray;
  color: #000;
  width: 100vw;
  height: 200px;
  margin-top: 200px;
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 15px;
  text-align: left;
}
#map-container {
  margin-top: -40px;
}
@media screen and (max-width: 768px) {
  .footer-container {
    background-color: lightgray;
    color: #000;
    width: 100vw;
    height: 100%;
    margin-top: 200px;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 15px;
    text-align: left;
  }
  #map-container {
    margin: 0 auto;
    text-align: center;
    align-content: center;
    align-items: center;
  }
}
